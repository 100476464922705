<template>
  <ValidationObserver v-slot="{ handleSubmit }">
   <form
    class="tile is-child amplify-signup-form is-light"
    novalidate="novalidate"
    @submit.prevent="handleSubmit(onSubmit)"
   >
    <transition name="fade" mode="out-in">
      <div key="form-initial" v-if="!submitted" >
        <div class="subtitle is-size-5 is-size-4-tablet">
          <p>{{formContent.formTitle}}</p>
        </div>
        <div class="field">
           <validation-provider rules="required|min:3" mode="eager" v-slot="{errors}">
            <div class="control has-icons-left has-icons-right">
                <input
                  class="input is-medium"
                  type="text"
                  placeholder="Name"
                  name="Name"
                  v-model="newsletterRequest.name"
                  novalidate="novalidate"
                  >
                <span class="icon is-left">
                    <i class="fas fa-user fa-sm"></i>
                </span>
                <span class="icon is-right">
                    <i class="fas fa-check fa-sm"></i>
                </span>
            </div>
            <span class="field-validation-error">{{ errors[0] }}</span>
           </validation-provider>
        </div>
        <div class="field">
           <validation-provider rules="required|email" mode="eager" v-slot="{errors}">
              <div class="control has-icons-left has-icons-right">
                  <input
                    class="input is-medium"
                    type="email"
                    name="Email"
                    placeholder="Email"
                    v-model="newsletterRequest.email"
                    novalidate="novalidate"
                    >
                  <span class="icon is-left">
                      <i class="fas fa-envelope fa-sm"></i>
                  </span>
                  <span class="icon is-right">
                      <i class="fas fa-check fa-sm"></i>
                  </span>
              </div>
              <span class="field-validation-error">{{ errors[0] }}</span>
            </validation-provider>
        </div>
        <div class="field">
            <div class="control">
                <button type="submit" class="is-link button is-medium" :class="{ 'is-loading': isLoading }">Subscribe</button>
            </div>
        </div>
      </div>
      <div key="form-submitted" v-if="submitted" class="content box has-padding-5">
        <span v-if="formContent.submittedImage" class="is-pulled-right image has-margin-bottom-3 has-margin-left-3">
            <img class="is-rounded" :src="formContent.submittedImage + '?anchor=center&amp;mode=crop&amp;width=140&amp;height=140'">
        </span>
        <h4 class="is-size-5">{{formContent.submittedTitle}}</h4>
        <div v-html="formContent.submittedText"></div>
      </div>
    </transition>
    </form>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'

export default {
  name: 'subscribe-form',
  data () {
    return {
      isLoading: false,
      submitted: false,
      formContent: {},
      api: '/Umbraco/Api/SignUp/SubmitNewsletterForm',
      newsletterRequest: {
        name: '',
        email: ''
      }
    }
  },
  methods: {
    onSubmit () {
      var self = this
      self.isLoading = true
      axios
        .post(this.api, this.newsletterRequest, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function () {
          self.isLoading = false
          self.submittedError = false
          self.submittedForm()
        })
        .catch(function (error) {
          console.log(error)
          self.isLoading = false
          self.submittedError = true
          self.submittedForm()
        })
    },
    submittedForm () {
      this.$data.submitted = true
    }
  },
  beforeMount () {
    this.formContent = window.amplify.form
  }
}
</script>
